import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { COUNTERS, PLAYLIST_COVER_DATA } from '@fnd/constants'
import { formatNumber } from '@fnd/core/libs/currency'
import { ROUTES } from '@fnd/screens/constants'
import { Link as Scroll } from 'react-scroll'
import Auth from '@fnd/components/Auth'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import { LogoIconPlain } from '@fnd/components/Logo/Logo'
import PromoCard from '@fnd/components/Home/PromoCard'
import { splitArray, shuffle } from '@fnd/core/libs/helpers'
import { TrustpilotMicroComboWidget } from '@fnd/components/Trustpilot'
import Icon from '@fnd/components/Icon'
import Marquee from 'react-fast-marquee'
import { motion } from 'framer-motion'

const HomeHero = () => {
  const IMAGE_ROWS = 3

  const playlists = PLAYLIST_COVER_DATA.map((playlist, idx) => {
    return {
      title: playlist.title,
      image: `/images/covers/cover_${++idx}.jpg`,
    }
  })

  const playlistRows = splitArray(shuffle(playlists), IMAGE_ROWS)
  const intl = useIntl()

  const animation = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
  }

  return (
    <div className="container">
      <div className="home-hero">
        <div className="home-hero-content">
          <motion.h1 {...animation}>
            <FormattedMessage id="home.main.title" />
          </motion.h1>

          <motion.h2>
            <FormattedMessage id="home.main.subtitle" />
          </motion.h2>

          <Auth login loginClasses="button-cta mt-2">
            <Button
              to={ROUTES.MATCH}
              variant="gradient"
              className="mt-2 button-cta"
            >
              <FormattedMessage id="home.main.start_now" />
            </Button>
          </Auth>

          <div className="flex flex-col flex-wrap items-center justify-center gap-2 mt-8 sm:flex-row">
            <Badge
              variant="primary-light"
              icon="exchange"
              className="px-4 text-base font-semibold md:text-lg"
              value={intl.formatMessage({
                id: 'home.solution.submissions.counter',
              })}
              label={formatNumber(COUNTERS.SUBMISSIONS)}
            />

            <Badge
              variant="primary-light"
              icon="user-music"
              className="px-4 text-base font-semibold md:text-lg"
              value={intl.formatMessage({ id: 'home.solution.artists.counter' })}
              label={formatNumber(COUNTERS.ARTISTS)}
            />

            <Badge
              variant="primary-light"
              icon="list-music"
              className="px-4 text-base font-semibold md:text-lg"
              value={intl.formatMessage({
                id: 'home.solution.playlists.counter',
              })}
              label={formatNumber(COUNTERS.PLAYLISTS)}
            />
          </div>

          <TrustpilotMicroComboWidget className="w-full mt-6" />

          <Scroll
            className="mt-8 home-hero-scroll-down"
            to="intro"
            spy
            smooth
            offset={-70}
            duration={500}
          >
            <Icon name="arrow-down" className="mr-2" />
            <FormattedMessage id="home.main.scroll_down" />
          </Scroll>
        </div>

        <PromoCard couponCode="XMAS10" className="my-8" />

        <div className="home-grid-wrapper">
          <div className="home-grid">
            {playlistRows.map((row, i) => (
              <Marquee
                key={i}
                direction={i % 2 === 0 ? 'left' : 'right'}
                className="marquee"
              >
                {row.map((playlist) => (
                  <div key={playlist.title} className="relative home-grid-cell">
                    {playlist.image && (
                      <img
                        src={playlist.image}
                        alt={playlist.title}
                        className="absolute inset-0 object-cover"
                      />
                    )}

                    <LogoIconPlain className="absolute top-3 right-3 text-white backdrop-blur-lg w-3 h-3 opacity-70 z-20" />

                    <span className="absolute bottom-2 left-3 leading-tight text-white text-xs md:text-sm font-bold text-balance max-w-[60%] z-20 opacity-90">
                      {playlist.title}
                    </span>
                  </div>
                ))}
              </Marquee>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeHero
