import React from 'react'
import Spinner from '@fnd/components/Spinner'
import { InvoiceCard } from '@fnd/components/Invoice'
import { useIntl } from 'react-intl'
import Alert from '@fnd/components/Alert'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import { ROUTES } from '@fnd/screens/constants'
import CountdownDate from '@fnd/components/Countdown/CountdownDate'
import Empty from '@fnd/components/Empty'
import parse from 'html-react-parser'
import {
  HowItWorks,
  PromoCompare,
  Mentions,
  Reviews,
  Artists,
} from '@fnd/components/Home'
import { CompareSection } from '@fnd/components/Plans'
import Faq from '@fnd/screens/Faq'
import SectionTitle from '@fnd/components/SectionTitle'
import Wrapper from '@fnd/components/Wrapper'
import { QUERIES } from '@fnd/constants'
import { useQuery } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'

const Promo = () => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERIES.PROMO.LIST],
    queryFn: () => spotimatchEndpoints.getFeaturedInvoices(),
  })

  const intl = useIntl()

  return (
    <Wrapper>
      <SectionTitle
        title={intl.formatMessage({ id: 'promo.page.bf.title' })}
        subtitle={intl.formatMessage({ id: 'promo.page.bf.subtitle' })}
        headingClasses="text-gradient-gold-fade"
        center
        badge={
          <Badge
            variant="gold-light"
            label="XMAS10"
            icon="tag"
          />
        }
      />

      <div className="flex flex-col items-center text-center gap-6 max-w-screen-md mx-auto mb-6">
        <CountdownDate
          endDate={new Date('2025-01-10')}
          className="ml-2"
        />

        <p className="text-lg md:text-xl text-balance">
          {parse(intl.formatMessage({ id: 'promo.body.xmas.1' }))}
        </p>

        <p className="text-lg md:text-xl text-balance">
          {parse(intl.formatMessage({ id: 'promo.body.xmas.2' }))}
        </p>

        <p className="text-lg md:text-xl text-balance">
          {parse(intl.formatMessage({ id: 'promo.body.xmas.3' }))}
        </p>
      </div>

      <div className="max-w-screen-md mx-auto">
        {isLoading && <Spinner />}

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {data?.length > 0 && data.map((invoice) => (
            <InvoiceCard
              key={invoice._id}
              accessToken={invoice?.access_token}
              invoiceId={invoice?._id}
              userId={invoice?.user_id}
              description={invoice?.description}
              amount={invoice?.amount}
              cover={invoice?.cover}
              discountPercent={invoice?.discount_percentage}
              discountAmount={invoice?.discount_amount}
              expirationDate={invoice?.expiration_date}
              discountExpirationDate={invoice?.discount_expiration}
              status={invoice?.status}
            />
          ))}
        </div>

        {data?.length === 0 && (
          <Empty
            icon="badge-percent"
            message={intl.formatMessage({ id: 'promo.empty_state' })}
          />
        )}
      </div>


      <div className="flex flex-col items-center text-center gap-6 max-w-screen-md mx-auto mt-6">
        <Alert
          variant="gold-light"
          className="w-full"
          label={intl.formatMessage({ id: 'promo.notice' })}
          cta={
            <Button
              small
              className="w-full md:max-w-[200px] mx-auto"
              icon="arrow-right"
              to={ROUTES.SUPPORT}
              variant="gold"
            >
              {intl.formatMessage({ id: 'navigation.support' })}
            </Button>
          }
        />

        <p className="text-lg md:text-xl text-balance">
          {parse(intl.formatMessage({ id: 'promo.footer.1' }))}{' '}
          <Badge variant="gold-light" icon="tag" className="font-semibold" label="XMAS10" />{' '}
          {parse(intl.formatMessage({ id: 'promo.footer.2' }))}
        </p>
      </div>

      <Artists />

      <HowItWorks showTitle />

      <PromoCompare className="mt-10" />

      <CompareSection />

      <Reviews />

      <Mentions />

      <Faq showTitle className="pb-16" />
    </Wrapper>
  )
}

export default Promo
