import React, { useState, useEffect } from 'react'
import { InvoiceTicket } from '@fnd/components/Invoice'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper'
import classNames from 'classnames'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useParams } from 'react-router-dom'

function Invoice({ className }) {
  const [invoice, setInvoice] = useState()
  const [loading, setLoading] = useState(false)
  const { invoiceId } = useParams()

  const classes = classNames({
    'py-8': true,
    [className]: className,
  })

  useEffect(() => {
    const getInvoice = async () => {
      setLoading(true)

      try {
        const invoiceData = await spotimatchEndpoints.getInvoice(invoiceId)
        setInvoice(invoiceData)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    getInvoice()
  }, [invoiceId])

  return (
    <Wrapper id="invoice" className={classes}>
      {loading && <Spinner />}

      {invoice ? (
        <InvoiceTicket
          accessToken={invoice?.access_token}
          invoiceId={invoice?._id}
          userId={invoice?.user_id}
          description={invoice?.description}
          amount={invoice?.amount}
          cover={invoice?.cover}
          featured={invoice?.featured}
          discountPercent={invoice?.discount_percentage}
          discountAmount={invoice?.discount_amount}
          expirationDate={invoice?.expiration_date}
          discountExpirationDate={invoice?.discount_expiration}
          status={invoice?.status}
        />
      ) : (
        <Empty />
      )}
    </Wrapper>
  )
}

export default Invoice
