import React, { useEffect } from 'react'
import { LogoFull } from '@fnd/components/Logo/Logo'
import Alert from '@fnd/components/Alert'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import { ROUTES } from '@fnd/screens/constants'
import { Payment, PaymentDetails, PaymentTotal } from '@fnd/components/Payment'
import { PaymentItem } from '@fnd/components/Plans/PaymentItem'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDiscountStore } from '@fnd/store'
import { getMediaUrl } from '@fnd/core/libs/media'
import { getDateFormat } from '@fnd/core/libs/helpers'

export function InvoiceTicket({
  accessToken,
  amount,
  cover,
  featured,
  description,
  discountPercent,
  discountAmount,
  discountExpirationDate,
  expirationDate,
  invoiceId,
  status,
  className,
}) {
  const intl = useIntl()
  const { locale } = intl

  const { setCanUseDiscount } = useDiscountStore()

  useEffect(() => {
    setCanUseDiscount(false)
  }, [])

  const classes = classNames({
    'invoice-ticket': true,
    [className]: className,
  })

  const getTime = (date) => {
    return dayjs(date).locale(locale).fromNow()
  }

  return (
    <>
      <div className={classes}>
        <div className="invoice-ticket-header">
          <div className="invoice-ticket-logo">
            <LogoFull />
          </div>

          <div className="flex flex-col items-end gap-2">
            <h6 className="m-0">{accessToken}</h6>

            {status === 'pending' ? (
              <Badge icon="hourglass" variant="blue-light">
                Pending
              </Badge>
            ) : (
              <Badge icon="check" variant="green-light">
                Paid
              </Badge>
            )}
          </div>
        </div>

        <div className="invoice-ticket-body">
          {cover && (
            <div className="invoice-ticket-cover mb-4">
              <img src={getMediaUrl(cover)} alt="Cover" />
            </div>
          )}
          {description && (
            <Alert className="mb-4">
              <p className="text-lg">{description}</p>
            </Alert>
          )}

          <PaymentDetails
            discountPercent={discountPercent}
            totalDiscountAmount={discountAmount}
            disableAffiliate={true}
            price={amount}
          >
            {discountExpirationDate && (
              <li>
                <label>
                  <FormattedMessage id="payment.discount_expiration" />
                </label>
                <div className="text-right">
                  <span className="block">
                    {dayjs(discountExpirationDate).format(
                      getDateFormat(locale, true)
                    )}
                  </span>
                  <span className="text-sm opacity-70">
                    {getTime(discountExpirationDate)}
                  </span>
                </div>
              </li>
            )}

            {expirationDate && (
              <li>
                <label>
                  <FormattedMessage id="payment.invoice_expiration" />
                </label>
                <div className="text-right">
                  <span className="block">
                    {dayjs(expirationDate).format(getDateFormat(locale, true))}
                  </span>
                  <span className="text-sm opacity-70">
                    {getTime(expirationDate)}
                  </span>
                </div>
              </li>
            )}
          </PaymentDetails>

          <PaymentTotal className="mt-4" paymentPrice={amount} price={amount} />
        </div>

        <div className="invoice-ticket-footer">
          {status === 'pending' ? (
            <Payment
              className="flex justify-center w-full"
              plan="invoice"
              title="Invoice"
              contract={true}
              label={<FormattedMessage id="payment.pay" />}
              disableCredits={true}
              disableAffiliate={true}
              disableCoupon={true}
              autoApply={false}
              maxCredits={0}
              price={amount}
              payload={{
                invoiceId,
              }}
            >
              <PaymentItem
                title="Invoice"
                subtitle={<FormattedMessage id="navigation.account_plan" />}
                icon="file-invoice-dollar"
              />
            </Payment>
          ) : (
            <Alert icon="check" variant="success">
              <FormattedMessage id="invoice.paid" />
            </Alert>
          )}
        </div>
      </div>

      {featured && (
        <div className="max-w-screen-sm mx-auto">
          <Alert
            variant="primary-light"
            className="w-full mt-4 text-center"
            label={intl.formatMessage({ id: 'promo.notice' })}
            cta={
              <Button
                small
                className="w-full md:max-w-[200px] mx-auto"
                icon="arrow-right"
                to={ROUTES.SUPPORT}
              >
                {intl.formatMessage({ id: 'navigation.support' })}
              </Button>
            }
          />
        </div>
      )}
    </>
  )
}

export default InvoiceTicket

InvoiceTicket.defaultProps = {
  variant: 'primary',
}
