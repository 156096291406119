import React from 'react'
import { useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import Snowfall from 'react-snowfall'
import { toastFeedback } from '@fnd/core/libs/toast'
import classNames from 'classnames'
import { ROUTES } from '@fnd/screens/constants'

const PromoCard = ({ couponCode, className }) => {
  const intl = useIntl()

  const classes = classNames({
    partners: true,
    section: true,
    'container max-w-screen-lg mx-auto': true,
    [className]: className,
  })

  const handleCouponCopy = () => {
    navigator.clipboard.writeText(couponCode)
    toastFeedback('success', intl.formatMessage({ id: 'messages.copied' }))
  }

  const Ticket = () => {
    return (
      <div className="z-10 transition-all duration-300 cursor-pointer ticket hover:scale-95" onClick={handleCouponCopy}>
        <div className="ticket-content-wrapper">
          <span className="font-mono text-2xl font-semibold md:text-4xl">
            {couponCode}
          </span>
          <span className="ticket-copy text-sm">
            {intl.formatMessage({ id: 'messages.click_to_copy' })}
          </span>
        </div>
      </div>
    )
  }

  return (
    <section className={classes}>
      <Snowfall />

      <div className="promo-card promo-card-gold">
        <div className="flex flex-col items-center justify-center gap-3">
          <Badge
            icon="badge-percent"
            variant="light"
            className="text-lg"
            to={ROUTES.PROMO}
            label={intl.formatMessage({ id: 'promo_card.xmas.badge' })}
          />

          <h3 className="m-0 text-3xl text-center md:text-5xl">
            {intl.formatMessage({ id: 'promo_card.xmas.title' })}
          </h3>
        </div>

        <Ticket />

        <div className="grid gap-3 md:gap-12 md:grid-cols-2 md:max-w-[70%] mx-auto">
          <div className="flex items-start gap-4">
            <Icon name="tag" className="pt-[0.1rem] text-xl" />
            <h4 className="m-0 text-lg font-light md:text-xl">
              {intl.formatMessage({ id: 'promo_card.xmas.discount' })}
            </h4>
          </div>

          <div className="flex items-start gap-4">
            <Icon name="clock" className="pt-[0.1rem] text-xl" />
            <h4 className="m-0 text-lg font-light md:text-xl">
              {intl.formatMessage({ id: 'promo_card.xmas.time' })}
            </h4>
          </div>
        </div>

        <div className="flex justify-center">
          <Button
            variant="gold"
            icon="arrow-right"
            className="md:min-w-[200px]"
            to={ROUTES.PROMO}
            label={intl.formatMessage({ id: 'promo_card.xmas.button' })}
          />
        </div>
      </div>
    </section>
  )
}

export default PromoCard
